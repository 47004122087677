<template>
    <div data-gradient="0.06" class="section section--banner">
        <div class="container">
            <div class="row">
                <!-- محتوای بخش بنر -->
            </div>
        </div>
    </div>
</template>

<script>
export default {};
</script>
