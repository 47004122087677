<template>
    <div class="testimonial--video">
        <div class="sw">
            <div class="swiper sw--testimonial-video">
                <div class="swiper-wrapper">
                    <swiper-slide v-for="(video, index) in videos" :key="index">
                        <div class="testimonial--video-item">
                            <video preload="none" :data-poster="video.poster">
                                <source :src="video.src" type="video/mp4" />
                            </video>
                            <div class="testimonial--video-btn"><i class="icon-play"></i></div>
                        </div>
                    </swiper-slide>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: 'TestimonialVideo',
    data() {
        return {
            videos: [
                { poster: require('@/assets/images/وبسیما.webp'), src: '@/assets/images/وبسیما.mp4' },
                { poster: require('@/assets/images/ازکی.webp'), src: '@/assets/images/Final_Azki.mp4' },
                { poster: require('@/assets/images/رضا-شیرازی-1.webp'), src: '@/assets/images/Reza-Shirazi_Final-2.mp4' },
                { poster: require('@/assets/images/طلای-تاج.webp'), src: '@/assets/images/طلای-تاج.mp4' },
                { poster: require('@/assets/images/IMG_20240707_113339_518.png'), src: '@/assets/images/خروجی_نهایی_ربانی.mp4' },
                { poster: require('@/assets/images/jam.jpeg'), src: '@/assets/images/jamshidi_final.mp4' },
                { poster: require('@/assets/images/صدرالدینی.webp'), src: '@/assets/images/صدرالدینی.mp4' }
            ]
        }
    }
}
</script>

<style scoped>
/* استایل‌های مربوط به کامپوننت را اینجا اضافه کنید */
</style>
