<template>
  <div class="smssolution-contents">
    <div class="smssolution-contents--desc">
      <h3 class="title">ارسال پیامک انبوه</h3>
      <p class="desc">
        یکی از ابزارهای بازاریابی و ارتباطی بسیار مهم برای هرکسب و کاری، ارسال پیامک انبوه می‌باشد.
      </p>
      <div class="general--iconiclist">
        <div class="general--iconiclist__item" v-for="(item, index) in features" :key="index">
          <i class="icon icon-shield"></i>
          <div class="text">
            <p class="title">{{ item.title }}</p>
            <p class="desc">{{ item.desc }}</p>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "TabContentSectionTwo",
  data() {
    return {
      features: [
        {
          title: "قیمت کاملا مقرون‌به‌صرفه",
          desc: "ارسال پیامک انبوه هزینه‌ای بسیار مقرون‌به‌صرفه نسبت به سایر ابزارهای بازاریابی دارد.",
        },
        {
          title: "تخفیف بیشتر با خرید پیامک بیشتر",
          desc: "با انتخاب پلن‌های بالاتر یا خرید شارژ بیشتر، تخفیف بیشتری دریافت کنید.",
        },
        // موارد دیگر...
      ],
    };
  },
};
</script>
