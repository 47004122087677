<template>
    <footer id="footer" class="footer-wrapper">
        <div class="container">
            <div class="footer-desc">
                <a class="logo-ft" href="https://rasapeyk.ir" title="rasapeyk.ir">
                    <img :src="require('@/assets/images/3-black-background-1.svg')" class="logo__ft" alt="Logo"
                        width="150" height="53" />
                </a>
                <p class="footer__about_desc">
                    حتما شما هم روزانه تعدادی پیامک که با هدف تبلیغ انجام می‌شوند را دریافت کرده‌اید...
                    <a href="https://rasapeyk.ir/">پنل اس ام اس</a> rasapeyk.ir به عنوان یکی از پیشگامان عرصه سامانه‌های ارسال
 rasapeyk.ir به عنوان یکی از پیشگامان عرصه سامانه های ارسال پیامک بر آن است که با ساده سازی ارسال اس ام اس از طریق وب، این امکان را برای تمامی اقشار جامعه فراهم نماید که هر فردی با هر سطح دانش و معلوماتی، اقدام به ارسال پیامک نماید، تاثیر آن را در کسب و کار خود مشاهده کند و از آن لذت ببرد. شما می توانید با چند کلیک اقدام به ارسال اس ام اس مورد نظر خود برای ده ها هزار نفر نمایید اما لحظه ای از زمان خود را هدر ندهید. سامانه اس ام اس و یا سامانه پیامک بستری مناسب را برای ارسال پیامک در کمترین زمان و مدیریت آنها فراهم آورده است.                </p>
                <div class="socials">
                    <a href="https://www.instagram.com/idehpardazan.rasapeyk.ir?igsh=MzRlODBiNWFlZA==" target="_blank"
                        rel="nofollow noopener noreferrer" title="Instagram">
                        <i class="icon-instagram"></i>
                    </a>
                    <a href="https://t.me/ipesms" target="_blank" rel="nofollow noopener noreferrer" title="Telegram">
                        <i class="icon-telegram"></i>
                    </a>
                    <a href="https://www.linkedin.com/company/sms-ir/?originalSubdomain=ir" target="_blank"
                        rel="nofollow noopener noreferrer" title="Linkedin">
                        <i class="icon-linkedin"></i>
                    </a>
                    <a href="https://www.aparat.com/rasapeyk.ir" target="_blank" rel="nofollow noopener noreferrer"
                        title="Aparat">
                        <i class="icon-aparat"></i>
                    </a>
                </div>
            </div>
        </div>

        <div class="footer-info">
            <div class="container">
                <div class="footer-info-wrap">
                    <div class="footer__menu contact">
                        <p class="footer-title">راه‌های ارتباطی</p>
                        <ul class="footer-contact">
                            <li class="phones">
                                <i class="icon-phone"></i>
                                <div class="footer-contact__link">
                                    <a dir="ltr" href="tel:0212853" rel="nofollow noopener noreferrer">0212853</a>
                                    <a dir="ltr" href="tel:02127684000"
                                        rel="nofollow noopener noreferrer">02127684000</a>
                                </div>
                            </li>
                            <li class="email">
                                <i class="icon-post"></i>
                                <span>کدپستی: 1458834954</span>
                            </li>
                            <li class="email">
                                <i class="icon-letter"></i>
                                <a href="mailto:info@rasapeyk.ir" rel="nofollow noopener noreferrer">info@rasapeyk.ir</a>
                            </li>
                            <li class="address">
                                <i class="icon-map"></i>
                                <span>تهران، خیابان آزادی، ناحیه نوآوری شریف... <a
                                        href="https://maps.app.goo.gl/u8jPF8Agf3fCaTTv7"
                                        rel="nofollow noopener noreferrer">(مشاهده در نقشه)</a></span>
                            </li>
                        </ul>
                    </div>

                    <div class="footer__menu nav">
                        <p class="footer-title">ایده پردازان</p>
                        <ul class="footer-sub-menu">
                            <li><a href="https://rasapeyk.ir/about-us/">درباره ما</a></li>
                            <li><a href="https://rasapeyk.ir/contact-us/">تماس با ما</a></li>
                            <li><a href="https://rasapeyk.ir/blog/">بلاگ</a></li>
                            <li><a href="#">فرصت های شغلی</a></li>
                            <li><a href="#">مسئولیت اجتماعی</a></li>
                        </ul>
                    </div>

                    <div class="footer__menu nav">
                        <p class="footer-title">برنامه نویسان</p>
                        <ul class="footer-sub-menu">
                            <li><a href="https://rasapeyk.ir/feature/%d9%be%db%8c%d8%a7%d9%85%da%a9-otp/">ارسال کد
                                    فعالسازی</a></li>
                            <li><a href="https://rasapeyk.ir/lab/">پلاگین‌های پیامکی</a></li>
                            <li><a href="https://rasapeyk.ir/web-service/">وب سرویس</a></li>
                            <li><a href="https://github.com/IPeCompany/">پکیج‌های گیت‌هاب</a></li>
                            <li><a href="https://rasapeyk.ir/rest-api/">راهنمای API</a></li>
                        </ul>
                    </div>

                    <div class="footer__menu nav">
                        <p class="footer-title">خدمات مشتریان</p>
                        <ul class="footer-sub-menu">
                            <li><a href="https://rasapeyk.ir/help/">راهنمای سامانه</a></li>
                            <li><a href="https://rasapeyk.ir/pricing/">قیمت پنل پیامکی</a></li>
                            <li><a href="https://rasapeyk.ir/faq/">سوالات متداول</a></li>
                            <li><a href="https://rasapeyk.ir/terms-and-conditions/">قوانین و مقررات</a></li>
                            <li><a
                                    href="https://rasapeyk.ir/%D9%87%D9%85%DA%A9%D8%A7%D8%B1%DB%8C-%D8%AF%D8%B1-%D9%81%D8%B1%D9%88%D8%B4/">کسب
                                    درآمد</a></li>
                        </ul>
                    </div>

                    <div class="footer__menu nav">
                        <p class="footer-title">دسترسی سریع</p>
                        <ul class="footer-sub-menu">
                            <li><a
                                    href="https://rasapeyk.ir/%D8%AE%D8%AF%D9%85%D8%A7%D8%AA%DB%8C-%D8%B3%D8%A7%D8%B2%DB%8C-%D8%AE%D8%B7%D9%88%D8%B7/">خدماتی
                                    سازی خطوط</a></li>
                            <li><a href="https://rasapeyk.ir/exclusive-lines/">شماره اختصاصی</a></li>
                            <li><a
                                    href="https://rasapeyk.ir/web-service/%d9%be%da%a9%db%8c%d8%ac-%d9%87%d8%a7%db%8c-%d9%88%d8%a8-%d8%b3%d8%b1%d9%88%db%8c%d8%b3/">پکیج‌های
                                    وب سرویس</a></li>
                            <li><a href="https://rasapeyk.ir/service/">امکانات</a></li>
                            <li><a href="https://typist.rasapeyk.ir/">سریعترین تایپیست</a></li>
                        </ul>
                    </div>
                </div>
                <div class="awwards-ft-box">
                    <button aria-label="Scroll to top" class="btn-scrolltop">
                        <i class="icon-scrollup"></i>
                    </button>
                    <div class="awwards-ft">
                        <a href="https://trustseal.enamad.ir/?id=18918&Code=oGTsSzrsYEAvMGCRWM7d">
                            <img width="92" height="92" :src="require('@/assets/images/Bitmap.webp')"
                                alt="Trust Seal" />
                        </a>
                        <a href="https://tehran.irannsr.org/trade_unit/2509468">
                            <img width="92" height="92"
                                :src="require('@/assets/images/NSR-Nezam-Senfi-Logo.webp')"
                                alt="Nezam Senfi Logo" />
                        </a>
                        <a href="https://eanjoman.ir/member/Zruqet5bdGBUVB00WqhFuBWxP">
                            <img src="https://eanjoman.ir/api/script?code=Zruqet5bdGBUVB00WqhFuBWxP" alt="Anjoman Logo"
                                title="انجمن صنفی" />
                        </a>
                    </div>
                </div>
            </div>
        </div>
    </footer>
</template>

<script>
export default {
    name: 'FooterComponent'
}
</script>

<style scoped>
/* Add your styles here */
</style>
