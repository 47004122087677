<template>
    <section data-gradient="0.1" id="blockcontent" class="section section--content pb-md pt-xl type1">
        <div class="content--type1">
            <div class="container">
                <div class="blockcontent-container">
                    <div class="blockcontent--text">
                        <div class="title-wrapper">
                            <h2 class="title fs-28 fw-250"><strong>ایــــده پـــردازان</strong> فراتر از یک سامانه پیامک
                            </h2>
                            <p class="subtitle fs-14">بـــــدون محـــدودیـت؛ انـــدیشیـــدیم، کـــار کـــردیم و بـــه
                                جـــلو حرکــــت کردیم.</p>
                        </div>
                        <div class="editor-content main-content">
                            <p>ما ایده پردازانی‌ها، از اواخر سال 1388 فعالیت خود را شروع کردیم. بدون محدودیت اندیشیدیم،
                                بدون محدودیت کار کردیم و بدون محدودیت به سمت جلو حرکت کردیم. حالا با بیش از 14 سال تجربه
                                در حوزه ارسال پیامک‌های تبلیغاتی و خدماتی یکی از پیشگامان این عرصه هستیم که توانستیم
                                رضایت کسب‌وکارهای مختلفی را در سراسر کشور به دست بیاوریم و تجربه لذت‌بخشی از بازاریابی
                                پیامکی برایشان رقم بزنیم&#8230;</p>
                        </div>
                        <div class="btns--box">
                            <a href="https://rasapeyk.ir/about-us/" class="btn btn--primary" target="_self">
                                <span class="txt">درباره ما</span>
                                <i class="icon-circle-arrow-l"></i>
                            </a>
                        </div>
                        <div class="blockcontent-features">
                            <div class="features--item" v-for="feature in features" :key="feature.title">
                                <i :class="feature.iconClass"></i>
                                <div class="features--content">
                                    <span class="features--content__num">
                                        <i>{{ feature.prefix }}</i>
                                        <b>{{ feature.value }}</b>
                                        <i>{{ feature.suffix }}</i>
                                    </span>
                                    <span class="features--content__title">{{ feature.title }}</span>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="blockcontent--img">
                        <img width="1293" height="1328" :src="imageSrc" class="attachment-full size-full" alt=""
                            decoding="async" />
                        <noscript>
                            <img width="1293" height="1328" :src="imageSrc" class="attachment-full size-full" alt=""
                                decoding="async" />
                        </noscript>
                    </div>
                </div>
            </div>
        </div>
    </section>
</template>

<script>
export default {
    name: 'BlockContent',
    data() {
        return {
            features: [
                {
                    iconClass: 'icon-star-shine',
                    prefix: '+',
                    value: '14',
                    suffix: '',
                    title: 'سال تجـــــربه'
                },
                {
                    iconClass: 'icon-usergroup',
                    prefix: '+',
                    value: '400',
                    suffix: 'K',
                    title: 'تعداد کل مشتریان'
                },
                {
                    iconClass: 'icon-stopwatch',
                    prefix: '%',
                    value: '99.99',
                    suffix: '',
                    title: 'آپ تـــــایم'
                }
            ],
            imageSrc: require("@/assets/images/white.webp")
        };
    }
};
</script>

<style scoped>
.blockcontent-container {
    display: flex;
    justify-content: space-between;
    /* برای فاصله مناسب بین دو باکس */
    align-items: center;
    /* برای قرارگیری عمودی در مرکز */
    flex-wrap: wrap;
    /* اگر صفحه کوچک شد، باکس‌ها به صورت عمودی قرار بگیرند */
    gap: 2rem;
    /* فاصله بین باکس‌ها */
}

.blockcontent--text {
    flex: 1;
    /* این باکس 50% از عرض پدر را بگیرد */
    max-width: 50%;
    /* حداکثر عرض برای این باکس */
}

.blockcontent--img {
    flex: 1;
    /* این باکس هم 50% از عرض پدر را بگیرد */
    max-width: 50%;
    /* حداکثر عرض برای این باکس */
}

@media (max-width: 768px) {
    .blockcontent-container {
        flex-direction: column;
        /* در دستگاه‌های کوچک، باکس‌ها زیر هم قرار بگیرند */
    }

    .blockcontent--text,
    .blockcontent--img {
        max-width: 100%;
        /* باکس‌ها در صفحه‌های کوچکتر تمام عرض صفحه را بگیرند */
    }
}
</style>
