<template>
    <div>
        <div id="mask"></div>
        <div class="fix-menu">
            <a class="menu--item login" target="_self" href="https://rasapeyk.ir/panel/">
                <i class="icon-user"></i>
                <b>ورود / ثبت نام</b>
            </a>
            <button class="menu--item demo" aria-label="مشاهده دمو">
                <i class="icon-widget"></i>
                <b>مشاهده دمو</b>
            </button>
            <a target="_blank" rel="nofollow noopener noreferrer" class="menu--item call" href="tel:0212853">
                <i class="icon-phone"></i>
                <b>مشاوره رایگان</b>
            </a>
        </div>
        <div id="menumobile" class="mobile-menu-wrapper">
            <div class="title-mm">
                <a href="https://rasapeyk.ir" title="rasapeyk.ir">
                    <img src="data:image/svg+xml,%3Csvg%20xmlns='http://www.w3.org/2000/svg'%20viewBox='0%200%20140%2051'%3E%3C/svg%3E"
                        class="img-logo logo1" alt="" width="140" height="51" decoding="async"
                        :data-lazy-src="require('@/assets/images/1-white-background-main-logo-2.svg')" />
                    <noscript><img :src="require('@/assets/images/1-white-background-main-logo-2.svg')"
                            class="img-logo logo1" alt="" width="140" height="51" decoding="async" /></noscript>
                    <img src="data:image/svg+xml,%3Csvg%20xmlns='http://www.w3.org/2000/svg'%20viewBox='0%200%20140%2051'%3E%3C/svg%3E"
                        class="img-logo logo2" alt="" width="140" height="51" decoding="async"
                        :data-lazy-src="require('@/assets/images/3-black-background-1.svg')" />
                    <noscript><img :src="require('@/assets/images/3-black-background-1.svg')"
                            class="img-logo logo2" alt="" width="140" height="51" decoding="async" /></noscript>
                </a>
                <span aria-label="close" role="button" tabindex="0" id="nomenumobile">
                    <i class="icon-close-circle1"></i>
                </span>
                <a class="btn btn--simple-primary" target="_self" href="https://rasapeyk.ir/panel/">
                    <i class="icon-user"></i>
                    <b>ورود / ثبت نام</b>
                </a>
            </div>
            <div class="main-mm">
                <ul id="menu-main" class="menu">
                    <li class="menu-item menu-item-home"><a href="https://rasapeyk.ir" aria-current="page">پنل پیامکی</a>
                    </li>
                    <li class="menu-item"><a href="https://rasapeyk.ir/pricing/">قیمت پنل پیامکی</a></li>
                    <li class="menu-item"><a href="https://rasapeyk.ir/bulk-send/">ارسال پیامک انبوه</a></li>
                    <li class="menu-item"><a href="https://rasapeyk.ir/web-service/">برنامه نویسان</a></li>
                    <li class="menu-item"><a href="https://rasapeyk.ir/lab/">افزونه های پیامکی</a></li>
                    <li class="menu-item"><a href="https://rasapeyk.ir/exclusive-lines/">شماره اختصاصی</a></li>
                    <li class="menu-item"><a
                            href="https://rasapeyk.ir/%D8%AE%D8%AF%D9%85%D8%A7%D8%AA%DB%8C-%D8%B3%D8%A7%D8%B2%DB%8C-%D8%AE%D8%B7%D9%88%D8%B7/">خدماتی
                            سازی خطوط</a></li>
                    <li class="menu-item"><a
                            href="https://rasapeyk.ir/%D9%87%D9%85%DA%A9%D8%A7%D8%B1%DB%8C-%D8%AF%D8%B1-%D9%81%D8%B1%D9%88%D8%B4/">کسب
                            درآمد</a></li>
                    <li class="menu-item"><a href="https://rasapeyk.ir/help/">راهنمای سامانه پیامک</a></li>
                    <li class="menu-item"><a href="https://rasapeyk.ir/blog/">دانشنامه پیامکی</a></li>
                    <li class="menu-item"><a href="https://rasapeyk.ir/about-us/">درباره ما</a></li>
                    <li class="menu-item"><a href="https://rasapeyk.ir/contact-us/">تماس با ما</a></li>
                </ul>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: 'MenuComponent'
}
</script>

<style scoped>
/* استایل‌های مورد نیاز برای کامپوننت‌های منو */
</style>
