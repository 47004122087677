<template>
  <div data-gradient="0.06" class="section section--plugins pb-lg pt-lg type1">
    <i class="bg" style="background-image:url('assets/images/bg-code-1.webp')"></i>
    <div class="webservice-container">
      <div class="container">
        <div class="text-center title-wrapper">
          <p class="title fs-28 fw-250">وب سرویس پیامکی</p>
          <p class="subtitle fs-14">وب سرویس پنل پیامک، از طریق سایت یا اپلیکیشن خود پیامک ارسال کنید</p>
        </div>

        <!-- اسلایدر -->
        <swiper :options="swiperOptions">
          <swiper-slide v-for="(chunk, index) in chunkedWebserviceItems" :key="index">
            <div class="swiper-slide-items">
              <!-- نمایش آیتم‌های متعدد در هر اسلاید -->
              <div v-for="(item, idx) in chunk" :key="idx" class="webservice-item">
                <div class="webservice-item__img">
                  <a :href="item.link" target="_self">
                    <img :src="item.imgSrc" :alt="item.imgAlt" :width="item.imgWidth" :height="item.imgHeight" />
                  </a>
                </div>
                <h3 class="webservice-item__title">
                  <a :href="item.link" target="_self">{{ item.title }}</a>
                </h3>
              </div>
            </div>
          </swiper-slide>
        </swiper>


        <!-- Btns Box Section -->
        <div class="btns--box">
          <a href="https://rasapeyk.ir/rest-api/" class="btn btn--primary" target="_self">
            <span class="txt"> مستندات API </span>
            <i class="icon-circle-arrow-l"></i>
          </a>
          <a href="https://rasapeyk.ir/lab//" class="btn btn--dark" target="_self">
            <span class="txt"> افزونه‌های پیامکی </span>
            <i class="icon-circle-arrow-l"></i>
          </a>
        </div>

      </div>
    </div>
  </div>
</template>

<script>
import { Swiper, SwiperSlide } from 'swiper/vue';
import 'swiper/swiper-bundle.css';

export default {
  name: "WebservicePlugins",
  components: {
    Swiper,
    SwiperSlide
  },
  data() {
    return {
        swiperOptions: {
            slidesPerView: 1, // نمایش 5 آیتم در هر اسلاید برای حالت دسکتاپ
            spaceBetween: 30,  // فاصله بین آیتم‌ها
            loop: true, // فعال کردن لوپ برای نمایش دائمی
            autoplay: {
                delay: 3000,
                disableOnInteraction: false,
            },
            pagination: {
                el: '.swiper-pagination',
                clickable: true,
            },
            navigation: {
                nextEl: '.swiper-button-next',
                prevEl: '.swiper-button-prev',
            },
            breakpoints: {
                640: {
                    slidesPerView: 3, // در موبایل 3 آیتم
                    spaceBetween: 10,
                },
                1024: {
                    slidesPerView: 5, // در دسکتاپ 5 آیتم
                    spaceBetween: 20, // کاهش فاصله بین آیتم‌ها
                },
                1440: {
                    slidesPerView: 5, // در مانیتورهای بزرگ‌تر همچنان 5 آیتم
                    spaceBetween: 30, // کمی فضای بیشتر بین آیتم‌ها در صفحه‌های بزرگ‌تر
                }
            },
         },
        webserviceItems: [
                {
                    imgSrc: require('@/assets/images/دیجیتس.svg'),
                    imgAlt: "دیجیتس",
                    imgWidth: "272.45",
                    imgHeight: "81.43",
                    title: "دیجیتس",
                    link: "https://rasapeyk.ir/lab"
                },
                {
                    imgSrc: require('@/assets/images/افزونه-وبزی-1.svg'),
                    imgAlt: "افزونه وبزی",
                    imgWidth: "252.29",
                    imgHeight: "74.81",
                    title: "سایت ساز وبزی",
                    link: "https://rasapeyk.ir/lab"
                },
                {
                    imgSrc: require('@/assets/images/چارسوق-2.svg'),
                    imgAlt: "قالب چهارسوق",
                    imgWidth: "145.89",
                    imgHeight: "139.93",
                    title: "قالب چهارسوق",
                    link: "https://rasapeyk.ir/lab"
                },
                {
                    imgSrc: require('@/assets/images/contant-form7-1.svg'),
                    imgAlt: "فرم تماس 7",
                    imgWidth: "188.6",
                    imgHeight: "139.73",
                    title: "فرم تماس 7",
                    link: "https://rasapeyk.ir/lab"
                },
                {
                    imgSrc: require('@/assets/images/میهن-پنل-1.svg'),
                    imgAlt: "افزونه میهن پنل",
                    imgWidth: "115.32",
                    imgHeight: "113.78",
                    title: "افزونه میهن پنل",
                    link: "https://rasapeyk.ir/lab/%d8%a7%d9%81%d8%b2%d9%88%d9%86%d9%87-%d9%85%db%8c%d9%87%d9%86-%d9%be%d9%86%d9%84/"
                },
                {
                    imgSrc: require('@/assets/images/افزونه-موبیس-1.svg'),
                    imgAlt: "افزونه موبیتس",
                    imgWidth: "132.23",
                    imgHeight: "138.46",
                    title: "افزونه موبیتس",
                    link: "https://rasapeyk.ir/lab"
                },
                {
                    imgSrc: require('@/assets/images/کراسنو-2.svg'),
                    imgAlt: "افزونه پیامک کراسنو",
                    imgWidth: "300",
                    imgHeight: "74.34",
                    title: "افزونه پیامک کراسنو",
                    link: "https://rasapeyk.ir/lab"
                },
                {
                    imgSrc: require('@/assets/images/افزونه-پلاتو-1.svg'),
                    imgAlt: "افزونه پلاتو",
                    imgWidth: "163.32",
                    imgHeight: "92.79",
                    title: "افزونه پلاتو",
                    link: "https://rasapeyk.ir/lab"
                },
                {
                    imgSrc: require('@/assets/images/افزونه-تیکتا-1.svg'),
                    imgAlt: "افزونه تیکت پشتیبانی تیکتا",
                    imgWidth: "194.97",
                    imgHeight: "69.1",
                    title: "افزونه تیکت پشتیبانی تیکتا",
                    link: "https://rasapeyk.ir/lab"
                },
                {
                    imgSrc: require('@/assets/images/افزونه-صورتحساب-سبز-1.svg'),
                    imgAlt: "افزونه صورتحساب سبز",
                    imgWidth: "300",
                    imgHeight: "72.36",
                    title: "افزونه صورتحساب سبز",
                    link: "https://rasapeyk.ir/lab"
                },
                {
                    imgSrc: require('@/assets/images/افزونه-whms.svg'),
                    imgAlt: "افزونه WHMCS",
                    imgWidth: "300",
                    imgHeight: "71.65",
                    title: "WHMCS",
                    link: "https://rasapeyk.ir/lab"
                },
                {
                    imgSrc: require('@/assets/images/دیجی-لند-2.svg'),
                    imgAlt: "قالب دیجی لند",
                    imgWidth: "300",
                    imgHeight: "70.22",
                    title: "قالب دیجی لند",
                    link: "https://rasapeyk.ir/lab"
                },
                {
                    imgSrc: require('@/assets/images/افزونه-دیوار-پلاس-1.svg'),
                    imgAlt: "قالب دیوار پلاس",
                    imgWidth: "194.5",
                    imgHeight: "112.26",
                    title: "قالب دیوار پلاس",
                    link: "https://rasapeyk.ir/lab"
                },
                {
                    imgSrc: require('@/assets/images/افزونه-گراویتی.svg'),
                    imgAlt: "افزونه گراویتی",
                    imgWidth: "284.72",
                    imgHeight: "80.21",
                    title: "گرویتی فرم",
                    link: "https://rasapeyk.ir/lab"
                },
                {
                    imgSrc: require('@/assets/images/اپتو-1.svg'),
                    imgAlt: "اپتو",
                    imgWidth: "147.84",
                    imgHeight: "105.68",
                    title: "ماژول اپلیکیشن ساز اپتو",
                    link: "https://rasapeyk.ir/lab"
                },
                {
                    imgSrc: require('@/assets/images/افزونه-حسابداری-هلو.svg'),
                    imgAlt: "افزونه حسابداری هلو",
                    imgWidth: "269.82",
                    imgHeight: "90.68",
                    title: "نرم افزار هلو",
                    link: "https://rasapeyk.ir/lab"
                },
                {
                    imgSrc: require('@/assets/images/کارگزاری-خوارزمی-1.svg'),
                    imgAlt: "فارسیکام",
                    imgWidth: "270.44",
                    imgHeight: "87.19",
                    title: "نرم افزار فارسیکام",
                    link: "https://rasapeyk.ir/lab"
                },
                {
                    imgSrc: require('@/assets/images/افزونه-وردپرس.svg'),
                    imgAlt: "افزونه وردپرس",
                    imgWidth: "170.08",
                    imgHeight: "133.46",
                    title: "وردپرس",
                    link: "https://rasapeyk.ir/lab"
                },
                {
                    imgSrc: require('@/assets/images/افزونه-وو-woo.svg'),
                    imgAlt: "ووکامرس",
                    imgWidth: "189.07",
                    imgHeight: "117.34",
                    title: "ووکامرس",
                    link: "https://rasapeyk.ir/lab"
                },
                {
                    imgSrc: require('@/assets/images/افزونه-دیدار-crm.svg'),
                    imgAlt: "افزونه دیدار CRM",
                    imgWidth: "235.02",
                    imgHeight: "74.09",
                    title: "دیدار CRM",
                    link: "https://support.didar.me/docs/%D8%A7%D8%AA%D8%B5%D8%A7%D9%84-%D8%A8%D9%87-%D8%A7%D9%86%D9%88%D8%A7%D8%B9-%D9%BE%D9%86%D9%84-%D9%BE%DB%8C%D8%A7%D9%85%DA%A9%DB%8C-%D8%AF%D8%B1-%D8%AF%DB%8C%D8%AF%D8%A7%D8%B1/"
                },
                {
                    imgSrc: require('@/assets/images/sep-logo-02-1-1.png'),
                    imgAlt: "سپیدار سیستم",
                    imgWidth: "262",
                    imgHeight: "117",
                    title: "سپیدار سیستم",
                    link: "https://rasapeyk.ir/lab"
                }
            ]
    };
  },
  computed: {
    chunkedWebserviceItems() {
      const chunkSize = 3; // تعداد آیتم‌های مورد نظر در هر اسلاید
      return this.webserviceItems.reduce((resultArray, item, index) => {
        const chunkIndex = Math.floor(index / chunkSize);

        if (!resultArray[chunkIndex]) {
          resultArray[chunkIndex] = []; // شروع یک دسته جدید
        }
        resultArray[chunkIndex].push(item);

        return resultArray;
      }, []);
    }
  }
};
</script>

<style scoped>
.swiper-slide-items {
  display: flex;
  justify-content: space-between;
}
.webservice-item {
  width: 30%; /* تنظیم اندازه آیتم‌ها */
}
</style>
