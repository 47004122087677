<template>
    <section data-gradient="0.05" class="section section--plans pb-lg">
        <div class="container">
            <div class="text-center title-wrapper">
                <div class="modifier">
                    <h2 class="title fs-28 fw-250">قیمت پنل‌های پیامکی</h2>
                    <p class="subtitle fs-14">مجموعه‌ای از امکانات و خدمات متنوع با قیمت‌هایی مقرون‌به‌صرفه تر</p>
                </div>
                <div class="btns--box">
                    <a href="https://rasapeyk.ir/pricing/" class="btn btn--primary" target="_blank">
                        <span class="txt">مقایسه پنل‌های پیامکی</span>
                        <i class="icon-circle-arrow-l"></i>
                    </a>
                </div>
            </div>
            <div class="plans-container">
                <div class="wrapper-plans hide-scrollbar">
                    <a v-for="plan in plans" :key="plan.title" :href="plan.link" class="plan-card">
                        <div class="plan-card--inner">
                            <i :class="plan.icon"></i>
                            <h3>{{ plan.title }}</h3>
                        </div>
                        <span class="price">
                            <b v-if="plan.price === 'رایگان'">{{ plan.price }}</b>
                            <span v-else>
                                {{ plan.price }} <span class="currency">هزار تومان</span>
                            </span>
                        </span>
                    </a>
                </div>
                <div class="plan-form">
                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 207 137" fill="none">
                        <path
                            d="M150.16 0.841797C150.16 0.841797 216.386 39.4231 205.313 73.1756C192.862 111.126 145.804 73.1756 115.813 73.1756C102.813 73.1756 81.3127 73.1756 75.8126 92.8419C71.9779 106.554 77.795 129.457 98.8126 135.342C111.313 138.842 129.105 129.041 134.813 115.342C139.813 103.342 138.889 53.9238 87.3126 42.3418C27.0061 28.7995 -8.68732 79.8418 3.31264 132.342"
                            stroke="#FFBE00" stroke-dasharray="9 9"></path>
                    </svg>
                    <div class="plan-form--content">
                        <div class="plan-form--content__txt">
                            <h2 style="font-size:17px;">اس ام اس مارکتینگ را با طرح همیشه رایگان ما شروع کنید</h2>
                            <p class="desc">بدون هیچ هزینه اولیه‌ای ثبت‌نام کنید، یک خط پیامکی رایگان دریافت کنید و
                                اولین پیامک خود را ارسال کنید...</p>
                        </div>
                        <div class="plan--action">
                            <div class="plan-form-input">
                                <span class="code">+98</span>
                                <input type="number" placeholder="9123357767" class="input-phonenumber" maxlength="11"
                                    pattern="\d*">
                                <input type="hidden" id="signup--link" value="https://app.rasapeyk.ir/auth/sign-up">
                            </div>
                            <button class="send-mobilenumber" aria-label="ثبت نام">ثبت نام</button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section>
</template>

<script>
export default {
    data() {
        return {
            plans: [
                {
                    title: 'پنل پایه',
                    link: 'https://app.rasapeyk.ir/auth/sign-up',
                    icon: 'icon-leaf',
                    price: 'رایگان'
                },
                {
                    title: 'پنل برنزی',
                    link: 'https://app.rasapeyk.ir/auth/sign-up',
                    icon: 'icon-award',
                    price: '199'
                },
                {
                    title: 'پنل نقره‌ای',
                    link: 'https://app.rasapeyk.ir/auth/sign-up',
                    icon: 'icon-star',
                    price: '899'
                },
                {
                    title: 'پنل طلایی',
                    link: 'https://app.rasapeyk.ir/auth/sign-up',
                    icon: 'icon-medal',
                    price: '1.999'
                }
            ]
        }
    }
}
</script>
