<template>
  <div class="smssolution-contents nodesc">
    <div class="smssolution-contents--cards">
      <!-- لیست کارت‌های ویژگی‌ها -->
      <div v-for="(feature, index) in features" :key="index" class="feature-card">
        <a :href="feature.link" class="feature-card__icon">
          <i :class="feature.icon"></i>
        </a>
        <h3>
          <a :href="feature.link" class="feature-card__title">{{ feature.title }}</a>
        </h3>
        <p class="line-2">
          <a :href="feature.link" class="feature-card__desc">{{ feature.desc }}</a>
        </p>
        <a :href="feature.link" class="btn-readmore">
          <span>اطلاعات بیشتر</span>
          <i class="icon-simple-arrow-left"></i>
        </a>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "TabContentSectionOne",
  data() {
    return {
  features: [
                {
                    id: 1,
                    link: 'https://rasapeyk.ir/feature/%d9%be%db%8c%d8%a7%d9%85%da%a9-otp/',
                    icon: 'icon-time',
                    title: 'پیامک OTP',
                    description: 'پیامک کدتایید سایت خود را به همه شماره‌ها حتی لیست سیاه ارسال کنید',
                },
                {
                    id: 2,
                    link: 'https://rasapeyk.ir/feature/%d8%a7%d8%b1%d8%b3%d8%a7%d9%84-%d8%a8%d9%87-%d8%a8%d8%a7%d9%86%da%a9-%d8%b4%d9%85%d8%a7%d8%b1%d9%87/',
                    icon: 'icon-message2',
                    title: 'ارسال به بانک شماره',
                    description: 'با بروزترین بانک شماره موبایل کشور، پیامک تبلیغاتی خود را ارسال کنید',
                },
                {
                    id: 3,
                    link: 'https://rasapeyk.ir/feature/%d9%be%db%8c%d8%a7%d9%85%da%a9-bts/',
                    icon: 'icon-mirroring-screen',
                    title: 'ارسال پیامک از دکل',
                    description: 'به‌صورت زنده به افرادی که در منطقه موردنظرتان هستند پیامک ارسال کنید',
                },
                {
                    id: 4,
                    link: 'https://rasapeyk.ir/feature/%d8%a7%d8%b1%d8%b3%d8%a7%d9%84-%d9%be%db%8c%d8%a7%d9%85%da%a9-%d9%87%d9%88%d8%b4%d9%85%d9%86%d8%af/',
                    icon: 'icon-message',
                    title: 'ارسال پیامک هوشمند',
                    description: 'کاملا منحصربه‌فرد با نام و اطلاعات مخاطبین‌تان پیامک ارسال کنید',
                },
                {
                    id: 5,
                    link: 'https://rasapeyk.ir/feature/%da%af%d8%b2%d8%a7%d8%b1%d8%b4%d8%a7%d8%aa-%d8%a7%d8%b1%d8%b3%d8%a7%d9%84-%d9%88-%d8%af%d8%b1%db%8c%d8%a7%d9%81%d8%aa/',
                    icon: 'icon-list',
                    title: 'گزارشات ارسال و دریافت',
                    description: 'گزارش دقیق تمام پیامک‌های ارسالی و دریافتی را مشاهده کنید',
                },
                {
                    id: 6,
                    link: 'https://rasapeyk.ir/feature/%d9%85%d9%86%d8%b4%db%8c-%d9%be%db%8c%d8%a7%d9%85%da%a9%db%8c/',
                    icon: 'icon-discuss',
                    title: 'منشی پیامکی',
                    description: 'پنل پیامک پاسخگوی خودکار پیامک‌های دریافتی شما خواهد بود',
                }
            ]
    };
  },
};
</script>
