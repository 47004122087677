<template>
    <div class="sw swiper-rtl">
        <div class="swiper sw--logos">
            <div class="swiper-wrapper">
                <swiper-slide v-for="(logo, index) in logos" :key="index">
                    <div class="logo--item">
                        <img :src="logo.src" :alt="logo.alt" class="attachment-medium size-medium" />
                    </div>
                </swiper-slide>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: 'LogoSwiper',
    data() {
        return {
            logos: [
                { src: require('@/assets/images/وبسیما.svg'), alt: 'وبسیما' },
                { src: require('@/assets/images/بیمه-ازکی.svg'), alt: 'بیمه ازکی' },
                { src: require('@/assets/images/وب-24.svg'), alt: 'وب 24' },
                { src: require('@/assets/images/طلای-تاج.svg'), alt: 'طلای تاج' },
                { src: require('@/assets/images/ویدان.svg'), alt: 'ویدان' },
                { src: require('@/assets/images/مجله-آسیا-تجارت.svg'), alt: 'مجله آسیا تجارت' },
                { src: require('@/assets/images/Rooydadhub-Logo-e1716881583898-300x98.png'), alt: '' },
                { src: require('@/assets/images/دیجیکالا.svg'), alt: 'دیجیکالا' },
                { src: require('@/assets/images/اسنپ-اکسپرس.svg'), alt: 'اسنپ اکسپرس' },
                { src: require('@/assets/images/ایسام.svg'), alt: 'ایسام' },
                { src: require('@/assets/images/ایران-تور.svg'), alt: 'ایران ایرتور' },
                { src: require('@/assets/images/بادی-اسپینر.svg'), alt: 'بادی اسپینر' },
                { src: require('@/assets/images/طبیعت.svg'), alt: 'طبیعت' },
                { src: require('@/assets/images/محمد-معظمی.svg'), alt: 'محمد معظمی' },
                { src: require('@/assets/images/کارگزاری-خوارزمی.svg'), alt: 'کارگزاری خوارزمی' },
                { src: require('@/assets/images/لبنیات-کافه.svg'), alt: 'لبنیات کاله' },
                { src: require('@/assets/images/هایپر-استار.svg'), alt: 'هایپر استار' },
                { src: require('@/assets/images/اسنوا.svg'), alt: 'اسنوا' },
                { src: require('@/assets/images/انجمن-صنفی-کارفرمایی-فروشگاه-های-اینترنتی-شهر-تهران.svg'), alt: 'انجمن صنفی کارفرمایی فروشگاه های اینترنتی شهر تهران' },
                { src: require('@/assets/images/باشگاه-آفتاب-انقلاب@2x-300x168.webp'), alt: 'باشگاه آفتاب انقلاب' },
                { src: require('@/assets/images/باما.svg'), alt: 'باما' },
                { src: require('@/assets/images/خبرگزاری-فارس.svg'), alt: 'خبرگزاری فارس' }
            ]
        }
    }
}
</script>

<style scoped>
/* استایل‌های مربوط به کامپوننت را اینجا اضافه کنید */
</style>
