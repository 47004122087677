<template>
  <div id="app">
    <HeaderComponent />
    <MenuComponent />
    <MainContent />
    <SmsSolutions /> <!-- این شامل تمام محتوای تب‌ها و بخش‌های داخلی است -->
    <TabContentPlans />
    <TabContentBanner />
    <WebservicePlugins />
    <BlockContent />
    <TestimonialVideos />
    <LogoSwiper />
    <RequestForm />
    <FooterComponent />
  </div>
</template>


<script>
import HeaderComponent from './components/HeaderComponent.vue'
import MenuComponent from './components/MenuComponent.vue'
import MainContent from './components/MainContent.vue'
import SmsSolutions from './components/SmsSolutions.vue'
import TabContentPlans from './components/TabContentPlans.vue';
import TabContentBanner from './components/TabContentBanner.vue';
import WebservicePlugins from './components/WebservicePlugins.vue';
import BlockContent from './components/BlockContent.vue';
import TestimonialVideos from './components/TestimonialVideos.vue';
import LogoSwiper from './components/LogoSwiper.vue';
import RequestForm from './components/RequestForm.vue';
import FooterComponent from './components/FooterComponent.vue';

export default {
  name: 'App',
  components: {
    HeaderComponent,
    MenuComponent,
    MainContent,
    SmsSolutions,
    TabContentPlans,
    TabContentBanner,
    WebservicePlugins,
    BlockContent,
    TestimonialVideos,
    LogoSwiper,
    RequestForm,
    FooterComponent
  }
}
</script>

<style>
/* استایل‌های کلی پروژه را اینجا بنویسید */
#app {
  font-family: 'KalamehWeb-Regular', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
  margin-top: 60px;
}
</style>
