<template>
  <div class="general-tabs-wrap">
    <div class="general-tabbtns hide-scrollbar">
      <div role="button" tabindex="0" class="tablinks" :class="{ active: activeTab === 0 }" @click="setActiveTab(0)">
        در یک نگاه
      </div>
      <div role="button" tabindex="0" class="tablinks" :class="{ active: activeTab === 1 }" @click="setActiveTab(1)">
        پیامک انبوه
      </div>
      <div role="button" tabindex="0" class="tablinks" :class="{ active: activeTab === 2 }" @click="setActiveTab(2)">
        پیامک تبلیغاتی
      </div>
      <div role="button" tabindex="0" class="tablinks" :class="{ active: activeTab === 3 }" @click="setActiveTab(3)">
        خط خدماتی
      </div>
    </div>

    <!-- نمایش محتوای هر تب -->
    <TabContent :activeTab="activeTab" />
  </div>
</template>

<script>
import TabContent from "./TabContent.vue";

export default {
  name: "TabContentSection",
  components: {
    TabContent,
  },
  data() {
    return {
      activeTab: 0, // تعیین تب فعال
    };
  },
  methods: {
    setActiveTab(tabIndex) {
      this.activeTab = tabIndex;
    },
  },
};
</script>
