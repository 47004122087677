<template>
    <div id="request-wrap">
        <div class="request-box-holder">
            <i class="icon-close close" @click="closeRequest"></i>
            <div class="close close-request-box" @click="closeRequest"></div>
            <div class="request-box">
                <div class="form-signup--single">
                    <div class="form-signup__txt">
                        <h2 class="title" style="font-size:17px;">
                            اس ام اس مارکتینگ را با طرح همیشه رایگان ما شروع کنید
                        </h2>
                        <p class="desc">
                            بدون هیچ هزینه اولیه‌ای ثبت‌نام کنید، یک خط پیامکی رایگان دریافت کنید و اولین پیامک خود را
                            ارسال کنید...
                        </p>
                    </div>
                    <div class="plan--action">
                        <div class="plan-form-input">
                            <span class="code">+98</span>
                            <input type="number" v-model="phoneNumber" placeholder="9123357767"
                                class="input-phonenumber" maxlength="11" pattern="\d*" />
                            <input type="hidden" :value="signupLink" />
                        </div>
                        <button class="btn btn--simple-primary send-mobilenumber" @click="submitForm"
                            aria-label="ثبت نام">
                            ثبت نام
                        </button>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: "RequestForm",
    data() {
        return {
            phoneNumber: "",
            signupLink: "https://app.rasapeyk.ir/auth/sign-up",
        };
    },
    methods: {
        submitForm() {
            if (this.phoneNumber) {
                // اینجا می‌توانید منطق ارسال فرم یا ریدایرکت را قرار دهید
                window.location.href = this.signupLink;
            } else {
                alert("لطفاً شماره تلفن خود را وارد کنید.");
            }
        },
        closeRequest() {
            // عملکرد بستن باکس درخواست
            document.getElementById("request-wrap").style.display = "none";
        },
    },
};
</script>

<style scoped>
/* استایل‌های مورد نیاز را اضافه کنید */
.request-box-holder {
    /* استایل دلخواه */
}

.input-phonenumber {
    /* استایل دلخواه */
}
</style>
