import { createApp } from 'vue';
import App from './App.vue';

// START Import CSS files
import './assets/styles/fonts.css';
import './assets/styles/grids.css';
import './assets/styles/icons.css';
import './assets/styles/general.css';
import './assets/styles/header.css';
import './assets/styles/buttons.css';
import './assets/styles/cards.css';
import './assets/styles/section-hero.css';
import 'swiper/swiper-bundle.css';
import './assets/styles/section-smssolutions.css';
import './assets/styles/section-banner.css';
import './assets/styles/section-plans.css';
import './assets/styles/section-plugins.css';
import './assets/styles/section-content.css';
import './assets/styles/section-testimonal.css';
import './assets/styles/section-editor.css';
import './assets/styles/footer.css';
import './assets/styles/style.css';

// Import Swiper and Swiper styles
import { Swiper as MySwiper, SwiperSlide } from 'swiper/vue';
import 'swiper/swiper-bundle.css';

// END Import CSS files

// START Import JS files
import $ from 'jquery';
window.$ = window.jQuery = $;

import './assets/js/Global.js';
import './assets/js/main.js';

// Vue Meta for Head Management
import { createHead } from '@vueuse/head';

const app = createApp(App);
const head = createHead();

app.use(head);

// Register Swiper components globally
app.component('MySwiper', MySwiper);
app.component('SwiperSlide', SwiperSlide);

// Mount Vue application
app.mount('#app');
