<template>
    <main id="main" class="modular-main">
        <canvas></canvas>
        <section data-gradient="0.2" id="hero" class="section section--hero pb-xs pt-sm intro">
            <div class="container">
                <div class="hero-container has-img not-canvas">
                    <i class="bg1"></i>
                    <i class="bg2"></i>
                    <div class="hero--text">
                        <p class="hero--toptitle">
                            پنل اس ام اس با بیشترین امکانات و بهترین تعرفه
                        </p>
                        <h1 class="hero--title">
                            پنــل پـیـامکــی <strong>ایــده‌پــردازان</strong>
                        </h1>
                        <p class="hero--desc">
                            سامانه rasapeyk.ir با بیش از 14 سال تجربه در عرصه ارسال پیامک تبلیغاتی و خدماتی این امکان را
                            فراهم کرده تا همه افراد بتوانند اقدام به ارسال پیامک کنند و تاثیر آن را در کسب‌وکار خود
                            مشاهده کنند و از آن لذت ببرند
                        </p>
                        <a href="https://app.rasapeyk.ir/auth/sign-up" class="btn btn--primary">
                            <span class="txt">
                                همین حالا <strong>رایگان</strong> ثبت نام کن
                            </span>
                            <i class="icon-circle-arrow-l"></i>
                        </a>
                    </div>
                    <div class="hero--img">
                        <img width="1189" height="1048" :src="require('@/assets/images/Frame.webp')" class="nolazy"
                            alt="" decoding="async" fetchpriority="high" />
                    </div>
                </div>
            </div>
        </section>
    </main>
</template>

<script>
export default {
    name: "MainContent",
};
</script>

<style scoped>
/* اینجا استایل‌های مربوط به این بخش را وارد کن */
</style>
