<template>
  <div
    class="home page-template page-template-templates page-template-template-modular page-template-templatestemplate-modular-php page page-id-25306">
    <div id="wrapper">
      <header id="header">
        <div class="header-primary">
          <div class="container">
            <div class="header-primary--inner">
              <button aria-label="منوی موبایل" class="mm-menu">
                <i class="icon-menu"></i>
              </button>
              <div class="header-primary--right">
                <a href="https://rasapeyk.ir" title="rasapeyk.ir" class="sms-logo">
                  <img :src="require('@/assets/images/1-white-background-main-logo-2.svg')" class="nolazy img-logo logo1" alt=""
                    width="144" height="50" data-skip-lazy="1" decoding="async" />
                  <img :src="require('@/assets/images/3-black-background-1.svg')" class="nolazy img-logo logo2" alt="" width="144"
                    height="50" data-skip-lazy="1" decoding="async" />
                </a>
                <div class="header-primary--nav">
                  <div class="menu-header-first-menu-container">
                    <ul id="menu-header-first-menu" class="header-wrap-menu">
                      <li id="menu-item-25986"
                        class="menu-item menu-item-type-custom menu-item-object-custom menu-item-25986">
                        <a href="https://rasapeyk.ir/pricing/">قیمت پنل پیامکی</a>
                      </li>
                      <li id="menu-item-25987"
                        class="menu-item menu-item-type-custom menu-item-object-custom menu-item-25987">
                        <a href="https://rasapeyk.ir/web-service/">برنامه نویسان</a>
                      </li>
                      <li id="menu-item-25988"
                        class="menu-item menu-item-type-custom menu-item-object-custom menu-item-25988">
                        <a href="https://rasapeyk.ir/lab/">افزونه پیامکی</a>
                      </li>
                      <li id="menu-item-25989"
                        class="menu-item menu-item-type-custom menu-item-object-custom menu-item-25989">
                        <a href="https://rasapeyk.ir/exclusive-lines/">شماره اختصاصی</a>
                      </li>
                      <li id="menu-item-25990"
                        class="menu-item menu-item-type-custom menu-item-object-custom menu-item-25990">
                        <a
                          href="https://rasapeyk.ir/%D8%AE%D8%AF%D9%85%D8%A7%D8%AA%DB%8C-%D8%B3%D8%A7%D8%B2%DB%8C-%D8%AE%D8%B7%D9%88%D8%B7/">خط
                          خدماتی</a>
                      </li>
                      <li id="menu-item-25991"
                        class="menu-item menu-item-type-custom menu-item-object-custom menu-item-25991">
                        <a
                          href="https://rasapeyk.ir/%D9%87%D9%85%DA%A9%D8%A7%D8%B1%DB%8C-%D8%AF%D8%B1-%D9%81%D8%B1%D9%88%D8%B4/">کسب
                          درآمد</a>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
              <div class="header-primary--left">
                <div class="switch-theme">
                  <button disabled aria-label="light theme" class="light--theme">
                    <i class="icon-sun"></i>
                  </button>
                  <button disabled aria-label="dark theme" class="dark--theme">
                    <i class="icon-moon"></i>
                  </button>
                </div>
                <button class="btn--header-demo" aria-label="مشاهده دمو">
                  <i class="icon-widget"></i>
                  <b>مشاهده دمو</b>
                </button>
                <a class="btn--header-login" target="_self" href="https://rasapeyk.ir/panel/">
                  <i class="icon-user"></i>
                  <b>ورود / ثبت نام</b>
                </a>
              </div>
            </div>
          </div>
        </div>
        <div class="header-secondary">
          <div class="header-secondary-container">
            <div class="container">
              <div class="header-secondary--inner">
                <div class="header-secondary--nav">
                  <div class="menu-new-menu-container">
                    <ul id="menu-new-menu" class="header-wrap-menu">
                      <li id="menu-item-25387"
                        class="menu-item menu-item-type-custom menu-item-object-custom current-menu-item current_page_item menu-item-home menu-item-25387">
                        <a href="https://rasapeyk.ir/" aria-current="page">پنل پیامکی</a>
                      </li>
                      <li id="menu-item-26167"
                        class="menu-item menu-item-type-custom menu-item-object-custom menu-item-26167">
                        <a href="https://rasapeyk.ir/service/">امکانات</a>
                      </li>
                      <li id="menu-item-27040"
                        class="menu-item menu-item-type-post_type menu-item-object-page menu-item-27040">
                        <a href="https://rasapeyk.ir/bulk-send/">ارسال پیامک انبوه</a>
                      </li>
                      <li id="menu-item-25388"
                        class="menu-item menu-item-type-custom menu-item-object-custom menu-item-25388">
                        <a href="https://rasapeyk.ir/blog/">دانشنامه پیامکی</a>
                      </li>
                      <li id="menu-item-25389"
                        class="menu-item menu-item-type-custom menu-item-object-custom menu-item-25389">
                        <a href="https://rasapeyk.ir/help/">راهنما</a>
                      </li>
                      <li id="menu-item-25390"
                        class="menu-item menu-item-type-custom menu-item-object-custom menu-item-25390">
                        <a href="https://rasapeyk.ir/faq/">سوالات متداول</a>
                      </li>
                      <li id="menu-item-25391"
                        class="menu-item menu-item-type-custom menu-item-object-custom menu-item-25391">
                        <a href="https://rasapeyk.ir/about-us/">درباره ما</a>
                      </li>
                      <li id="menu-item-25392"
                        class="menu-item menu-item-type-custom menu-item-object-custom menu-item-25392">
                        <a href="https://rasapeyk.ir/contact-us/">تماس با ما</a>
                      </li>
                    </ul>
                  </div>
                </div>
                <a rel="nofollow noopener noreferrer" target="_blank" class="header--phone"
                  href="tel:0212853">021-2853<i class="icon-phone"></i></a>
              </div>
            </div>
          </div>
        </div>
      </header>
    </div>
  </div>
</template>

<script>
export default {
  name: 'HomePage',
};
</script>

<style scoped>
/* Add styles here if needed */
</style>
