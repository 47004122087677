<template>
  <div class="smssolution-contents">
    <div class="smssolution-contents--desc">
      <h2 class="title">پیامک تبلیغاتی</h2>
      <p class="desc">
        پیامک تبلیغاتی، یک ابزار مهم در دنیای تبلیغات است که وظیفه خود را برای جذب و حفظ مشتریان به خوبی انجام داده است.
      </p>
      <div class="general--iconiclist">
        <div class="general--iconiclist__item" v-for="(item, index) in features" :key="index">
          <i class="icon icon-shield"></i>
          <div class="text">
            <p class="title">{{ item.title }}</p>
            <p class="desc">{{ item.desc }}</p>
          </div>
        </div>
      </div>
    </div>
    <div class="smssolution-contents--cards">
      <div class="wrapper-features hide-scrollbar">
        <div v-for="(feature, index) in featuresCards" :key="index" class="feature-card">
          <a :href="feature.link" class="feature-card__icon">
            <i :class="feature.icon"></i>
          </a>
          <h3>
            <a :href="feature.link" class="feature-card__title">{{ feature.title }}</a>
          </h3>
          <p class="line-2">
            <a :href="feature.link" class="feature-card__desc">{{ feature.desc }}</a>
          </p>
          <a :href="feature.link" class="btn-readmore">
            <span> اطلاعات بیشتر </span>
            <i class="icon-simple-arrow-left"></i>
          </a>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "TabContentSectionThree",
  data() {
    return {
      features: [
        {
          title: "تبدیل مخاطب به مشتری",
          desc: "با ارسال پیامک تبلیغاتی هدفمند، مخاطب‌ها را به مشتریان جدید کسب و کارتان تبدیل کنید.",
        },
        {
          title: "حفظ مشتری و تبدیل به مشتری وفادار",
          desc: "از طریق پیامک با مشتریان فعلی خود ارتباط مداوم داشته باشید و آن‌ها را به مشتریان وفادار تبدیل کنید.",
        },
        {
          title: "بازگرداندن مشتریان قدیمی",
          desc: "برای مشتریان قدیمی، پیشنهادات و کدهای تخفیف ارسال کنید و آن‌ها را به مشتریان فعال تبدیل کنید.",
        },
      ],
      featuresCards: [
        {
          title: "به تفکیک استان و شهرها",
          desc: "به استان و شهرهای کل کشور به تفکیک پیامک تبلیغاتی ارسال کنید.",
          link: "https://sms.ir/feature/%d8%a8%d9%87-%d8%aa%d9%81%da%a9%db%8c%da%a9-%d8%a7%d8%b3%d8%aa%d8%a7%d9%86-%d9%88-%d8%b4%d9%87%d8%b1%d9%87%d8%a7/",
          icon: "icon-message",
        },
        {
          title: "به تفکیک کدپستی و شهر",
          desc: "به محله‌های مختلف شهرهای کشور پیامک تبلیغاتی ارسال کنید.",
          link: "https://sms.ir/feature/%d8%a8%d9%87-%d8%aa%d9%81%da%a9%db%8c%da%a9-%da%a9%d8%af%d9%be%d8%b3%d8%aa%db%8c-%d9%88-%d8%b4%d9%87%d8%b1/",
          icon: "icon-post",
        },
        {
          title: "به تفکیک اصناف کل کشور",
          desc: "به صاحبان مشاغل مختلف در سراسر کشور پیامک تبلیغاتی ارسال کنید.",
          link: "https://sms.ir/feature/%d8%a8%d9%87-%d8%aa%d9%81%da%a9%db%8c%da%a9-%d8%a7%d8%b5%d9%86%d8%a7%d9%81-%da%a9%d9%84-%da%a9%d8%b4%d9%88%d8%b1/",
          icon: "icon-tag",
        },
      ],
    };
  },
};
</script>
