<template>
  <div class="smssolution-tabcontents">
    <div v-show="activeTab === 0">
      <TabContentSectionOne />
    </div>
    <div v-show="activeTab === 1">
      <TabContentSectionTwo />
    </div>
    <div v-show="activeTab === 2">
      <TabContentSectionThree />
    </div>
    <!-- تب‌های بعدی در اینجا اضافه شوند -->
  </div>
</template>

<script>
import TabContentSectionOne from "./TabContentSectionOne.vue";
import TabContentSectionTwo from "./TabContentSectionTwo.vue";
import TabContentSectionThree from "./TabContentSectionThree.vue";

export default {
  name: "TabContent",
  props: {
    activeTab: Number, // دریافت شماره تب فعال
  },
  components: {
    TabContentSectionOne,
    TabContentSectionTwo,
    TabContentSectionThree,
  },
};
</script>
