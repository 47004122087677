<template>
  <section data-gradient="0.06" class="section section--smssolutions pb-md">
    <div class="container">
      <div class="smssolution-container section-tabs">
        <div class="text-center title-wrapper xs">
          <h2 class="title fs-28 fw-250">پنل اس ام اس ایده پردازان</h2>
          <p class="subtitle fs-14">
            استفاده از امکانات اولیه پنل بدون پرداخت هزینه!
          </p>
        </div>
        <div class="smssolution-tabs">
          <TabContentSection />
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import TabContentSection from "./TabContentSection.vue";

export default {
  name: "SmsSolutions",
  components: {
    TabContentSection,
  },
};
</script>
